const Hots = {"data":[
    {"name":"Perinton Hots","id":"ChIJ2fYoNQ4z0YkRPfQrkuHMrkM","lat":43.06804394933815,"lng":-77.4386740935688, "address":"12 Courtney Dr, Fairport, NY 14450, USA","type":"hot"},
    {"name":"Chili Hots","id":"ChIJeVGruq5T0YkRgqvXvS6tOcY","lat":43.09338431950987,"lng":-77.79782617261637, "address":"3774 Chili Ave, Rochester, NY 14624, USA","type":"hot"},
    {"name":"Fairport Hots","id":"ChIJ3STMbqvM1okRtS7aJ2zz51Y","lat":43.10243499133437,"lng":-77.46522260090178, "address":"1226 Fairport Rd, Fairport, NY 14450, USA","type":"hot"},
    {"name":"JT's Pizza and Plates","id":"ChIJJ8ugdPfL1okR2MUqkT7sTtI","lat":43.11405339410524,"lng":-77.48401107204768, "address":"108 Main St, East Rochester, NY 14445, USA","type":"hot"},
    {"name":"ER Hots","id":"ChIJl9rECEXL1okREgfJtf8YWuE","lat":43.11331409411144,"lng":-77.48576940090582, "address":"115 W Commercial St, East Rochester, NY 14445, USA","type":"hot"},
    {"name":"Marks Texas Hots","id":"ChIJVwfMGg-11okRIrTFIFNA4nA","lat":43.146539558081805,"lng":-77.59410047148695, "address":"487 Monroe Ave, Rochester, NY 14607, USA","type":"hot"},
    {"name":"Nick Tahou Hots","id":"ChIJfRpzCPe01okRZdKmmvPsJWE","lat":43.15391613099207,"lng":-77.62117604290775, "address":"320 W Main St, Rochester, NY 14608, USA","type":"hot"},
    {"name":"Lake Hots","id":"ChIJExqyQEC01okRq3_4mUn_xgY","lat":43.17465423384331,"lng":-77.62919667177316, "address":"534 Lake Ave, Rochester, NY 14613, USA","type":"hot"},
    {"name":"Steve T Hots","id":"ChIJHVlzxqez1okRZDMTL95CH_o","lat":43.16508753242782,"lng":-77.68691364290864, "address":"2260 Lyell Ave, Rochester, NY 14606, USA","type":"hot"},
    {"name":"West Ridge Hots","id":"ChIJk0SQUK-21okRzlVk11vy034","lat":43.20215223736171,"lng":-77.64236297177443, "address":"584 W Ridge Rd, Rochester, NY 14615, USA","type":"hot"},
    {"name":"Spencerport Hots","id":"ChIJk0SQUK-21okRzlVk11vy034","lat":43.18220403460182,"lng":-77.80351264291589, "address":"500 S Union St, Spencerport, NY 14559, USA","type":"hot"},
    {"name":"Henrietta Hots","id":"ChIJ3aE-z3dL0YkR6M-E9cJvajc","lat":43.07204502072478,"lng":-77.64434257176629, "address":"4018 W Henrietta Rd, Rochester, NY 14623, USA","type":"hot"},
    {"name":"Wimpy's Burger Basket (Gates)","id":"ChIJFVkA6-ey1okRkharxoqw508","lat":43.14778713019867,"lng":-77.70975144291616, "address":"2160 Buffalo Rd, Rochester, NY 14624, USA","type":"hot"},
    {"name":"Wimpy's Burger Basket (Greece)","id":"ChIJyaFP1f-w1okR8purE2BVZcI","lat":43.24125815807935,"lng":-77.69591717148707, "address":"850 Long Pond Rd, Rochester, NY 14612, USA","type":"hot"},
    {"name":"Jimmy Z's Texas Hots","id":"ChIJhUPA6-UB1IkR64iMvDY5Unc","lat":43.215285439049495,"lng":-77.93856527177016, "address":"53 Main St, Brockport, NY 14420, USA","type":"hot"},
    {"name":"Culver Hots","id":"ChIJPyWh8f_J1okR_dG_Av7C0S4","lat":43.188550635825244,"lng":-77.5605314006314, "address":"2286 Culver Rd, Rochester, NY 14609, USA","type":"hot"},
    {"name":"Bella's Greece Hots","id":"ChIJI-dHwzCx1okRdLxr1hjTPd8","lat":43.222794639810644,"lng":-77.66200234290908, "address":"745 Maiden Ln, Rochester, NY 14615, USA","type":"hot"},
    {"name":"Webster Hots","id":"ChIJm0l1xwHP1okRAw9NpsISyRc","lat":43.212244838657604,"lng":-77.42809727177084, "address":"55 E Main St #3244, Webster, NY 14580, USA","type":"hot"},
    {"name":"Binghamton Hots","id":"ChIJ2wNxlm_v2okRLqw9piGgGN4","lat":42.09788655800251,"lng":-75.91334444262552, "address":"128 Washington St, Binghamton, NY 13901, USA","type":"hot"},
    {"name":"Scottsville Hots","id":"ChIJL_SNpzxP0YkRXPJdX_XQxvg","lat":43.029954315346195,"lng":-77.7413309717676, "address":"3892 Scottsville Rd, Scottsville, NY 14546, USA","type":"hot"},
    {"name":"Empire Hots","id":"ChIJazUoVhTJ1okRvmtAvUfAmjI","lat":43.20229315787631,"lng":-77.49674804262882, "address":"2209 Empire Blvd, Webster, NY 14580, USA","type":"hot"},
    {"name":"Penfield Hots","id":"ChIJfYPF1mTL1okRCd4KVNOTrSY","lat":43.12971355809045,"lng":-77.47802497148646, "address":"1794 Penfield Rd, Penfield, NY 14526, USA","type":"hot"},
    {"name":"Marshall Street Bar and Grill ","id":"ChIJ-xZQlQa11okRzvUsQ8RQfSU","lat":43.15021363072059,"lng":-77.60056327176848, "address":"81 Marshall St, Rochester, NY 14607, USA","type":"res"},
    {"name":"The Old Stone Tavern","id":"ChIJe4oewQK11okRqfO068FcWo0","lat":43.14026482922015,"lng":-77.6046487429197, "address":"758 South Avenue. Rochester, NY 14620, USA","type":"res"},
    {"name":"Angry Goat Pub","id":"ChIJ-zkCpxC11okR0cxV65PjGds","lat":43.13999895787656,"lng":-77.59601464263069, "address":"938 S Clinton Ave, Rochester, NY 14620, USA","type":"res"},
    {"name":"Skylark Lounge","id":"ChIJNTynGai11okRI79xVWO6tEo","lat":43.15396730429719,"lng":-77.59745127205251, "address":"40 S Union St, Rochester, NY 14607, USA","type":"res"},
    {"name":"The Exchange","id":"ChIJd842RDFy04kRr0P-ensaIhk","lat":43.15013693070265,"lng":-77.61158417176834, "address":"251 Exchange Blvd, Rochester, NY 14608, USA","type":"res"},
    {"name":"Jeremiah's","id":"ChIJhV7uO2y11okRnjebCN54ZLg","lat":43.13902552926896,"lng":-77.5801249717722, "address":"1104 Monroe Ave, Rochester, NY 14620, USA","type":"res"},
    {"name":"Ken's Pizza Corner","id":"ChIJ4ZqH5V611okReCEL3--We3E","lat":43.12786052784214,"lng":-77.56550227177335, "address":"1860 Monroe Ave, Rochester, NY 14618, USA","type":"res"},
    {"name":"Genny Brew House","id":"ChIJsV5Z9VG01okRe3DPZHBhekk","lat":43.16356223241851,"lng":-77.61489257177229, "address":"25 Cataract St, Rochester, NY 14605, USA","type":"res"},
    {"name":"JB Quimbies","id":"ChIJ2RtgEZNK0YkRmKpq9YlgLrE","lat":43.088996222687456,"lng":-77.58283044290808, "address":"3259 S Winton Rd, Rochester, NY 14623, USA","type":"res"},
    {"name":"Elmwood Inn","id":"ChIJ5RreMlG11okRtsSLBk72xn0","lat":43.12542285786066,"lng":-77.61678264263098, "address":"1256 Mt Hope Ave, Rochester, NY 14620, USA","type":"res"},
    {"name":"Highland Park Diner","id":"ChIJudplrhC11okR83ljkkBYh5I","lat":43.13952895806931,"lng":-77.59556657149363, "address":"960 S Clinton Ave, Rochester, NY 14620, USA","type":"res"},
    {"name":"The Red Fern","id":"ChIJE9n1Hgu11okRRExi-EX67ZY","lat":43.149749130448185,"lng":-77.58652954291153, "address":"283 Oxford St, Rochester, NY 14607, USA","type":"res"},
    {"name":"Dicky's Corner Pub","id":"ChIJ97VDexm11okRNGDoW6UOZCA","lat":43.13920412929883,"lng":-77.60002427177075, "address":"791 Meigs St #2137, Rochester, NY 14620, USA","type":"res"},
    {"name":"Frontier Field","id":"ChIJXyqp3Fm01okRHFboTKl6bv4","lat":43.158127494928955,"lng":-77.6199864612116, "address":"One Morrie Silver Way, Rochester, NY 14608, USA","type":"res"},
    {"name":"Jose Joe's","id":"ChIJYXfywX-v1okRwyJlt9j5GUk","lat":43.18789723552311,"lng":-77.69926887177364, "address":"2570 Ridgeway Ave, Rochester, NY 14626, USA","type":"res"},
    {"name":"McCann's Local Meats","id":"ChIJ1wDTEBu11okRLOT6hj1P9cg","lat":43.142824329569265,"lng":-77.59989004290887, "address":"739 S Clinton Ave, Rochester, NY 14620, USA","type":"res"},
    {"name":"Sticky Lips BBQ","id":"ChIJM8YcxwRL0YkRk_4D_gwejSY","lat":43.087836222718515,"lng":-77.61516107177313, "address":"830 Jefferson Rd, Rochester, NY 14623, USA","type":"res"},
    {"name":"Charlie's Restaurant","id":"ChIJLWphIXvJ1okRaEYF-AG73I4","lat":43.18856783542776,"lng":-77.50543984291193, "address":"1843 Empire Blvd, Webster, NY 14580, USA","type":"res"},
    {"name":"Dogtown","id":"ChIJWd5j6Q211okR_FKplQ9MTZI","lat":43.14384065808142,"lng":-77.58972557148603, "address":"691 Monroe Ave, Rochester, NY 14607, USA","type":"res"},
]};

const MapStyles = {"data":[{elementType:'geometry',stylers:[{color:'#242f3e'}]},
    {elementType:'labels.text.stroke',stylers:[{color:'#242f3e'}]},
    {elementType:'labels.text.fill',stylers:[{color:'#746855'}]},
    {featureType:'administrative.locality',elementType:'labels.text.fill',stylers:[{color:'#d59563'}]},
    {featureType:'poi',elementType:'labels.text.fill',stylers:[{color:'#d59563'}]},
    {featureType:'poi.park',elementType:'geometry',stylers:[{color:'#263c3f'}]},
    {featureType:'poi.park',elementType:'labels.text.fill',stylers:[{color:'#6b9a76'}]},
    {featureType:'road',elementType:'geometry',stylers:[{color:'#38414e'}]},
    {featureType:'road',elementType:'geometry.stroke',stylers:[{color:'#212a37'}]},
    {featureType:'road',elementType:'labels.text.fill',stylers:[{color:'#9ca5b3'}]},
    {featureType:'road.highway',elementType:'geometry',stylers:[{color:'#746855'}]},
    {featureType:'road.highway',elementType:'geometry.stroke',stylers:[{color:'#1f2835'}]},
    {featureType:'road.highway',elementType:'labels.text.fill',stylers:[{color:'#f3d19c'}]},
    {featureType:'transit',elementType:'geometry',stylers:[{color:'#2f3948'}]},
    {featureType:'transit.station',elementType:'labels.text.fill',stylers:[{color:'#d59563'}]},
    {featureType:'water',elementType:'geometry',stylers:[{color:'#17263c'}]},
    {featureType:'water',elementType:'labels.text.fill',stylers:[{color:'#515c6d'}]},
    {featureType:'water',elementType:'labels.text.stroke',stylers:[{color:'#17263c'}]}]};

export { Hots, MapStyles };